<template>
  <div>
    {{ espece }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return  {

        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/especes'
        }),
        espece(){
            let e = this.especes.find(item => item.uid === this.object.espece)
            if(e) return e.libelle
            return '-'
        }
    }
}
</script>

<style>

</style>